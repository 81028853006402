import { useFormik } from "formik";
import { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  // getAdvancedSearch,
  updateConfig,
} from "../../../store/slices/Search/AdvancedSearch";
// import useObserver from "../../../utils/hooks/useObserver";
import { useSearchParams } from "react-router-dom";
import { updateProfileConfig } from "../../../store/slices/Profile";
import html2pdf from "html2pdf.js";
import moment from "moment";
import {
  useGetAdvancedSearchResultsQuery,
  useGetSearchFiltersQuery,
} from "../../../store/queries/Search";
import { exportAdvancedSearch } from "./api";
import Assets from "../../../assets/Assets";
import { Image } from '@wac-ui-dashboard/wac_component_library'

import Style from "./advancedSearch.module.scss";

const useAdvancedSearch = () => {
  const [params] = useSearchParams();
  const printRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.advancedSearch);
  const [skip, setSkip] = useState(true);
  const [searchSkip, setSearchSkip] = useState(true);
  const [isFetchLoading, setIsFetchLoading] = useState();
  const { selectedProfiles, showPdf } = useSelector((state) => state.profile);
  const [showScroll, setShowScroll] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdf2Loading, setPdf2Loading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [exportOption, setExportOption] = useState(100);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedView, setSelectedView] = useState("compact");
  const [isOpen, setIsOpen] = useState(true);
  // const [page, setPage] = useState(1);
  const { data: optionsData, isSuccess: isSearchFilterSuccess } =
    useGetSearchFiltersQuery();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  //setting date variables
  let today = new Date();
  let pastDate = new Date();
  pastDate.setDate(today.getDate() - 30);

  const formik = useFormik({
    initialValues: {
      looking_for: null,
      age_from: null,
      age_to: null,
      height_from: null,
      height_to: null,
      religion: null,
      branch: [],
      registered_platform: null,
      caste: [],
      residing_country: null,
      residing_state: null,
      residing_district: [],
      special_case: null,
      job_location: [],
      star_id: null,
      // registration_type: ["1", "3", "4", "5", "6", "7", "8", "9"],
      registration_type: [],
      admin_verified: false,
      have_photo: null,
      main_education: [],
      user_education: [],
      marital_status: [],
      main_job: [],
      user_job: [],
      date_type: 1,
      children: null,
      agreement_status: null,
      // register_from_date: getFormatedDate(pastDate),
      // register_to_date: getFormatedDate(today),
      register_from_date: null,
      register_to_date: null,
      entire_data: true,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.searchValues = { ...values };
        })
      );
      setSearchSkip(false);
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
    },
  });

  const {
    data: advancedSearchResults = {},
    isError,
    isSuccess,
    isFetching,
  } = useGetAdvancedSearchResultsQuery(
    {
      ...state.searchValues,
      page: state.currentPage,
      per_page: state.currentPageSize,
    },
    { skip: searchSkip }
  );

  useEffect(() => {
    if (
      selectedProfiles?.length === advancedSearchResults?.data?.data?.length
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    //eslint-disable-next-line
  }, [selectedProfiles, advancedSearchResults?.data?.data]);

  let paginationOptions = [
    { label: "15 Per page", value: 15 },
    { label: "30 Per page", value: 30 },
    { label: "45 Per page", value: 45 },
  ];

  const handleViewChange = (view) => {
    setSelectedView(view);
    // dispatch(
    //   updateProfileConfig((state) => {
    //     state.selectedProfiles = [];
    //   })
    // );
  };

  const handlePagination = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
      dispatch(
        updateConfig((state) => {
          state.advancedSearchStatus = "idle";
          state.advancedSearchData = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleSearch = () => {
    dispatch(
      updateConfig((state) => {
        state.advancedSearchStatus = "idle";
        state.advancedSearchData = {};
        state.currentPage = 1;
      })
    );
    formik.handleSubmit();
    setIsOpen(false);
  };
  const handleReset = () => {
    formik.resetForm();
    dispatch(
      updateConfig((state) => {
        state.advancedSearchStatus = "idle";
        state.advancedSearchData = {};
      })
    );
  };

  const handleDownloadClick = (type) => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = state.selectedProfiles.filter(
          (a) => a !== activeProfile
        );
        state.showPdf = true;
      })
    );

    setSkip(false);
  };

  const handleDownloadPdf = async () => {
    setPdfLoading(true);
    const element = printRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [0, 0, 0, 0],
        filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;

    setPdfLoading(false);
    setSkip(true);
  };

  const handleSelect = (id, checked, type) => {
    const newIds =
      type === "grid" ? id : Array.isArray(id) ? id.map((item) => item) : [];
    if (type === "grid") {
      if (checked) {
        dispatch(
          updateProfileConfig((state) => {
            state.selectedProfiles = [...state.selectedProfiles, newIds];
          })
        );
      } else {
        dispatch(
          updateProfileConfig((state) => {
            state.selectedProfiles = state.selectedProfiles.filter(
              (prevID) => prevID !== id
            );
          })
        );
      }
    } else {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [
            // ...state.selectedProfiles,
            ...(Array.isArray(newIds) ? newIds : [newIds]),
          ];
        })
      );
    }
  };

  const handleSelectTable = (id) => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = id;
      })
    );
  };

  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  // // eslint-disable-next-line

  const handleClick = (next_id) => {
    sessionStorage.setItem("active", next_id);
    const newIds = ids.filter((id) => id !== next_id).join(",");
    window.open(
      `${window.location.origin}/${`profile?active=${next_id}&id=${newIds}`}`
    );
  };

  const getRowCompact = (feild, data) => {
    const rows = {
      photo: (feild, data) =>
        data["thumbnail_image"] ? (
          <div
            className={Style.image_inner}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(data["id"])}
          >
            <Image
              className="pro-mb-0 pro-rounded-2"
              style={{ cursor: "pointer"}}
              src={
                data["thumbnail_image"]
                  ? data["thumbnail_image"]
                  : data["gender"] === "Male"
                  ? Assets.MaleProfile
                  : Assets.FemaleProfile
              }
              // alt="Thumbnail"
              height={89}
              width={89}
              onClick={() => handleClick(data["id"])}
              onError={(e) =>
                (e.currentTarget.src =
                  data["gender"] === "Male"
                    ? Assets.MaleProfile
                    : Assets.FemaleProfile)
              }
            />
            <Image
              className="pro-mb-0 pro-rounded-2"
              style={{ cursor: "pointer"}}
              src={
                data["thumbnail_image"]
                  ? data["thumbnail_image"]
                  : data["gender"] === "Male"
                  ? Assets.MaleProfile
                  : Assets.FemaleProfile
              }
              // alt="Thumbnail"
              height={89}
              width={89}
              onClick={() => handleClick(data["id"])}
              onError={(e) =>
                (e.currentTarget.src =
                  data["gender"] === "Male"
                    ? Assets.MaleProfile
                    : Assets.FemaleProfile)
              }
            />
          </div>
        ) : (
          <div
            className={Style.image_inner}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(data["id"])}
          >
            <Image
              className="pro-mb-0 pro-rounded-2"
              style={{ cursor: "pointer" }}
              src={
                data["thumbnail_image"]
                  ? data["thumbnail_image"]
                  : data["gender"] === "Male"
                  ? Assets.MaleProfile
                  : Assets.FemaleProfile
              }
              // alt="Thumbnail"
              height={89}
              width={89}
              onClick={() => handleClick(data["id"])}
              onError={(e) =>
                (e.currentTarget.src =
                  data["gender"] === "Male"
                    ? Assets.MaleProfile
                    : Assets.FemaleProfile)
              }
            />
            <Image
              className="pro-mb-0 pro-rounded-2"
              style={{ cursor: "pointer"}}
              src={
                data["thumbnail_image"]
                  ? data["thumbnail_image"]
                  : data["gender"] === "Male"
                  ? Assets.MaleProfile
                  : Assets.FemaleProfile
              }
              // alt="Thumbnail"
              height={89}
              width={89}
              onClick={() => handleClick(data["id"])}
              onError={(e) =>
                (e.currentTarget.src =
                  data["gender"] === "Male"
                    ? Assets.MaleProfile
                    : Assets.FemaleProfile)
              }
            />
          </div>
        ),

      id_info: (feild, data) => (
        <div
          className="col"
          style={{ cursor: "pointer" }}
          onClick={() => handleClick(data["id"])}
        >
          {data["reg_no"] && (
            <p className="pro-mb-0 pro-fw-bold">{data["reg_no"]}</p>
          )}
          {data["nest_id"] && (
            <p className="pro-mb-0 pro-fw-bold">{data["nest_id"]}</p>
          )}
        </div>
      ),

      basic_info: (feild, data) => (
        <div
          className="col"
          style={{ cursor: "pointer" }}
          onClick={() => handleClick(data["id"])}
        >
          {(data["name"] || data["age_yrs"]) && (
            <p className="pro-mb-0 pro-fw-bold">{`${data["name"] || ""} (${
              data["age_yrs"] || ""
            })`}</p>
          )}
          {(data["gender"] || data["height_cms"]) && (
            <p className="pro-mb-0 ">{data["gender"] || ""}</p>
          )}
          {(data["height_cms"] || data["height_cms"]) && (
            <p className="pro-mb-0 pro-fw-bold">{data["height_cms"] || ""}</p>
          )}
          {data["religion"] && <p className="pro-mb-0">{data["religion"]}</p>}
          {data["star"] && <p className="pro-mb-0">{data["star"]}</p>}
        </div>
      ),

      detailed_info: (feild, data) => (
        <div className="col">
          {data["education_category"] && (
            <p className="pro-mb-0">{data["education_category"]}</p>
          )}
          {data["educational_details"] && (
            <p className="pro-mb-0 pro-fw-bold">
              {data["educational_details"]}
            </p>
          )}
          {data["job_category"] && (
            <p className="pro-mb-0">{data["job_category"]}</p>
          )}
          {data["job_details"] && (
            <p className="pro-mb-0 pro-fw-bold">{data["job_details"]}</p>
          )}
        </div>
      ),

      contact_info: (feild, data) => (
        <div className="col">
          {data["mobile_number_1"] && (
            <p className="pro-mb-0 pro-fw-bold">
              Mobile Number 1:{data["mobile_number_1"]}
            </p>
          )}
          {data["mobile_number_2"] && (
            <p className="pro-mb-0">
              Mobile Number 2:{data["mobile_number_2"]}
            </p>
          )}
          {data["whatsapp_number"] && (
            <p className="pro-mb-0 pro-fw-bold">
              WhatsApp Number:{data["whatsapp_number"]}
            </p>
          )}
          {data["communication_address"] && (
            <p className="pro-mb-0">{data["communication_address"]}</p>
          )}
          {data["district_and_state"] && (
            <p className="pro-mb-0">{data["district_and_state"]}</p>
          )}
        </div>
      ),

      other_info: (feild, data) => (
        <div className="col">
          {data["package_name"] && (
            <p className="pro-mb-0 pro-fw-bold">{data["package_name"]}</p>
          )}
          {data["agreement_at_branch"] && (
            <p className="pro-mb-0 pro-fw-bold">
              {data["agreement_at_branch"]}
            </p>
          )}
          {data["house_sq_feet"] && (
            <p className="pro-mb-0">{data["house_sq_feet"]}</p>
          )}
          {data["land_info"] && <p className="pro-mb-0">{data["land_info"]}</p>}
        </div>
      ),

      common: (feild, data) =>
        data[feild] ? <p className="pro-mb-0">{data[feild]}</p> : null,
    };

    return rows?.[feild]?.(feild, data) ?? rows.common(feild, data);
  };

  const getRowList = (feild, data) => {
    const rows = {
      photo: (feild, data) =>
        data["thumbnail_image"] ? (
          
          <div className={Style.image_inner}  style={{ cursor: "pointer"}} onClick={() => handleClick(data["id"])}>
            
            <Image
              className="pro-mb-0 pro-rounded-2"
              style={{ cursor: "pointer"}}
              src={
                data["thumbnail_image"]
                  ? data["thumbnail_image"]
                  : data["gender"] === "Male"
                  ? Assets.MaleProfile
                  : Assets.FemaleProfile
              }
              // alt="Thumbnail"
              height={50}
              width={50}
              onClick={() => handleClick(data["id"])}
              // onError={(e) =>
              //   (e.currentTarget.src =
              //     data["gender"] === "Male"
              //       ? Assets.MaleProfile
              //       : Assets.FemaleProfile)
              // }
            />
            <Image
              className="pro-mb-0 pro-rounded-2"
              style={{ cursor: "pointer"}}
              src={
                data["thumbnail_image"]
                  ? data["thumbnail_image"]
                  : data["gender"] === "Male"
                  ? Assets.MaleProfile
                  : Assets.FemaleProfile
              }
              // alt="Thumbnail"
              height={50}
              width={50}
              onClick={() => handleClick(data["id"])}
              onError={(e) =>
                (e.currentTarget.src =
                  data["gender"] === "Male"
                    ? Assets.MaleProfile
                    : Assets.FemaleProfile)
              }
            />
            
          </div>
        ) : <div className={Style.image_inner}  style={{ cursor: "pointer"}} onClick={() => handleClick(data["id"])}>
            
        <Image
          className="pro-mb-0 pro-rounded-2"
          style={{ cursor: "pointer"}}
          src={
            data["thumbnail_image"]
              ? data["thumbnail_image"]
              : data["gender"] === "Male"
              ? Assets.MaleProfile
              : Assets.FemaleProfile
          }
          // alt="Thumbnail"
          height={50}
          width={50}
          onClick={() => handleClick(data["id"])}
          // onError={(e) =>
          //   (e.currentTarget.src =
          //     data["gender"] === "Male"
          //       ? Assets.MaleProfile
          //       : Assets.FemaleProfile)
          // }
        />
        <Image
          className="pro-mb-0 pro-rounded-2"
          style={{ cursor: "pointer"}}
          src={
            data["thumbnail_image"]
              ? data["thumbnail_image"]
              : data["gender"] === "Male"
              ? Assets.MaleProfile
              : Assets.FemaleProfile
          }
          // alt="Thumbnail"
          height={50}
          width={50}
          onClick={() => handleClick(data["id"])}
          onError={(e) =>
            (e.currentTarget.src =
              data["gender"] === "Male"
                ? Assets.MaleProfile
                : Assets.FemaleProfile)
          }
        />
        
      </div>,

      id_info: (feild, data) => (
        <div
          className={`col`}
          onClick={() => handleClick(data["id"])}
          style={{ cursor: "pointer" }}
        >
          {data["reg_no"] && (
            <p
              className={`pro-mb-0 text-truncate pro-fw-bold ${Style.max_content}`}
            >
              {data["reg_no"] && ` ${data["reg_no"]} `}
              {data["reg_no"] && data["nest_id"] && " | "}
              {data["nest_id"] && `${data["nest_id"]}`}
            </p>
          )}
        </div>
      ),

      basic_info: (feild, data) => (
        <div
          className={`text-truncate ${Style.max_content}`}
          title={`${data["name"] || ""} (${data["age_yrs"] || ""})${
            data["name"] && data["height_cms"] ? " | " : ""
          }${data["height_cms"] || ""}${
            data["name"] && data["religion"] ? " | " : ""
          }${data["religion"] || ""}`}
          style={{ cursor: "pointer" }}
          onClick={() => handleClick(data["id"])}
        >
          {(data["name"] || data["age_yrs"]) && (
            <p className="pro-mb-0 pro-mb-0 text-truncate pro-fw-bold">
              {`${data["name"] || ""} (${data["age_yrs"] || ""})`}
              {data["name"] && data["height_cms"] && " | "}
              {data["height_cms"] && `${data["height_cms"]}`}
              {data["name"] && data["religion"] && " | "}
              {data["religion"] && `${data["religion"]}`}
            </p>
          )}
        </div>
      ),
      detailed_info: (feild, data) => (
        <div className={`col text-truncate ${Style.max_content}`}>
          {data["education_category"] && (
            <p
              className={`pro-mb-0 text-truncate ${Style.max_content}`}
              title={`${data["educational_details"] || ""}${
                data["educational_details"] && data["job_details"] ? " | " : ""
              }${data["job_details"] || ""}`}
            >
              {data["educational_details"] &&
                ` ${data["educational_details"]} `}
              {data["educational_details"] && data["job_details"] && " | "}
              {data["job_details"] && `${data["job_details"]}`}
            </p>
          )}
        </div>
      ),

      contact_info: (feild, data) => (
        <div className="col">
          {data["mobile_number_1"] && (
            <p
              className={`pro-mb-0 text-truncate pro-fw-bold ${Style.max_content}`}
            >
              {data["mobile_number_1"] && ` ${data["mobile_number_1"]} `}
              {data["mobile_number_1"] && data["whatsapp_number"] && " | "}
              {data["whatsapp_number"] && ` ${data["whatsapp_number"]}`}
            </p>
          )}
        </div>
      ),

      other_info: (feild, data) => (
        <div className="col">
          {data["package_name"] && (
            <p
              className={`pro-mb-0 text-truncate ${Style.max_content}`}
              title={`${data["package_name"] || ""}${
                data["package_name"] && data["agreement_at_branch"] ? " | " : ""
              }${
                data["agreement_at_branch"]
                  ? `Agreement at Branch: ${data["agreement_at_branch"]}`
                  : ""
              }`}
            >
              {data["package_name"] && ` ${data["package_name"]} `}
              {data["package_name"] && data["agreement_at_branch"] && " | "}
              {data["agreement_at_branch"] &&
                `Agreement at Branch: ${data["agreement_at_branch"]}`}
            </p>
          )}
        </div>
      ),

      common: (feild, data) =>
        data[feild] ? <p className="pro-mb-0">{data[feild]}</p> : null,
    };

    return rows?.[feild]?.(feild, data) ?? rows.common(feild, data);
  };

  const closePdfModal = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showPdf = false;
      })
    );
  };

  const handleSelectAll = () => {
    // Toggle the isChecked state
    setIsChecked((prev) => !prev);

    if (!isChecked) {
      // If isChecked is true, select all items
      const selectedProfileIds = advancedSearchResults?.data?.data?.map(
        (item) => item?.profile_data?.id
      );

      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = selectedProfileIds;
        })
      );
    } else {
      // If isChecked is false, deselect all items
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
    }
  };

  const handleExportClick = () => {
    setExportLoading(true);
    exportAdvancedSearch({
      ...state.searchValues,
      export_count: exportOption,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `advanced_search_users_${moment(Date.now()).format(
          "MM_DD_YYYY_HH_mm_ss"
        )}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setExportLoading(false);
    });
  };

  const hasExportPermission = useMemo(() => {
    let permission = advancedSearchResults?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_export")
    );
    return permission?.[0]?.can_export ?? false;
    // eslint-disable-next-line
  }, [advancedSearchResults]);

  const hasDownloadPdfPermission = useMemo(() => {
    let permission = advancedSearchResults?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_download")
    );
    return permission?.[0]?.can_download ?? false;
    // eslint-disable-next-line
  }, [advancedSearchResults]);

  const handleDownloadTable = async () => {
    setPdf2Loading(true);
    const element = tableRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [5, 5],
        filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;
    setPdf2Loading(false);
  };

  return {
    handlePagination,
    handlePageSize,
    setIsFetchLoading,
    closePdfModal,
    handleDownloadPdf,
    handleScrollToTop,
    setIsOpen,
    // lastElement,
    handleSearch,
    handleReset,
    handleSelect,
    handleDownloadClick,
    setExportOption,
    handleSelectAll,
    handleExportClick,
    handleDownloadTable,
    getRowCompact,
    getRowList,
    handleSelectTable,
    isOpen,
    searchSkip,
    hasDownloadPdfPermission,
    hasExportPermission,
    exportLoading,
    optionsData,
    isSearchFilterSuccess,
    exportOption,
    formik,
    state,
    showScroll,
    selectedProfiles,
    activeProfile,
    printRef,
    tableRef,
    showPdf,
    skip,
    isFetchLoading,
    paginationOptions,
    currentPage: state?.currentPage,
    advancedSearchResults,
    isFetching,
    isSuccess,
    isError,
    pdfLoading,
    pdf2Loading,
    isChecked,
    selectedView,
    setSelectedView,
    handleViewChange,
  };
};

export default useAdvancedSearch;
