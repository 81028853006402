import { Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import useMemoValues from "./hooks/useMemoValues";
import usePartnerForm from "./hooks/usePartnerForm";

const PartnerForm = ({
  star,
  checked,
  entireData,
  activeFilter,
  adminVerified,
  registered,
  state,
}) => {
  const {
    formik,
    optionsData,
    isOptionDataSuccess,
    isPreferenceDataSuccess,
    handleSave,
  } = usePartnerForm({
    star,
    checked,
    entireData,
    adminVerified,
    registered,
    activeFilter,
  });

  const { casteValue, stateValue, districtValue } = useMemoValues(
    formik,
    optionsData,
    isOptionDataSuccess,
    isPreferenceDataSuccess
  );

  return (
    <div className="pro-m-5">
      <div className="row">
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Religion
            </label>
            <Select
              id="religion"
              placeholder={"Select"}
              options={optionsData?.religionList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.religion_name}
              value={optionsData?.religionList?.filter?.(
                (c) => formik.values?.religion?.toString() === c.id?.toString()
              )}
              onChange={(value) => {
                formik.setFieldValue("religion", value?.id);
                formik.setFieldValue("caste", []);
              }}
              className={"pro-input"}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Caste
            </label>
            <Select
              isMulti
              id="caste"
              placeholder={"Select"}
              options={casteValue}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.caste_name}
              value={casteValue?.filter?.((c) =>
                formik.values.caste?.includes?.(c.id?.toString())
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "caste",
                  value.map((a) => a.id?.toString())
                );
              }}
              className={`pro-input`}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Marital Status
            </label>
            <Select
              id="marital_status"
              isMulti
              isClearable={true}
              placeholder={"Select"}
              options={optionsData.maritalStatusList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.marital_status}
              value={optionsData.maritalStatusList?.filter?.((c) =>
                formik.values.marital_status?.includes?.(c.id)
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "marital_status",
                  value.map((a) => a.id)
                );
              }}
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Special Case
            </label>
            <Select
              id="special_case"
              isMulti
              placeholder={"Select"}
              isClearable={true}
              options={optionsData.specialCaseList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.special_case}
              value={optionsData.specialCaseList?.filter?.((c) =>
                formik.values.special_case?.includes?.(c.id?.toString())
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "special_case",
                  value.map((a) => a.id?.toString())
                );
              }}
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-3">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Age From
            </label>
            <Select
              id="age_from"
              placeholder={"Select"}
              isClearable={true}
              options={optionsData.ageList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.age}
              value={optionsData.ageList?.filter(
                (m) => formik.values.age_from?.toString() === m.id?.toString()
              )}
              onChange={(value) =>
                formik.setFieldValue("age_from", value?.id || null)
              }
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-3">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Age To
            </label>
            <Select
              id="age_to"
              placeholder={"Select"}
              isClearable={true}
              options={optionsData.ageList?.filter(
                (p) => p?.id > formik.values.age_from
              )}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.age}
              value={optionsData.ageList?.filter(
                (m) => formik.values.age_to?.toString() === m.id?.toString()
              )}
              onChange={(value) =>
                formik.setFieldValue("age_to", value?.id || null)
              }
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-3">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Height From
            </label>
            <Select
              id="height_from"
              placeholder={"Select"}
              isClearable={true}
              options={optionsData.heightList}
              getOptionValue={(option) => option?.height_value}
              getOptionLabel={(option) => option?.height}
              value={optionsData.heightList?.filter(
                (m) =>
                  formik.values.height_from?.toString?.() ===
                  m.height_value?.toString()
              )}
              onChange={(value) =>
                formik.setFieldValue("height_from", value?.height_value || null)
              }
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-3">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Height To
            </label>
            <Select
              id="height_to"
              placeholder={"Select"}
              isClearable={true}
              options={optionsData.heightList?.filter(
                (p) => p?.height_value > formik.values.height_from
              )}
              getOptionLabel={(option) => option?.height}
              getOptionValue={(option) => option?.height_value}
              value={optionsData.heightList?.filter(
                (m) =>
                  formik.values.height_to?.toString() ===
                  m.height_value?.toString()
              )}
              onChange={(value) =>
                formik.setFieldValue("height_to", value?.height_value || null)
              }
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Education Category
            </label>
            <Select
              id="parent_education"
              isMulti
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              options={optionsData.parentEducationCategory}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.parent_category}
              value={optionsData.parentEducationCategory?.filter((m) =>
                formik.values.parent_education?.includes?.(m.id?.toString?.())
              )}
              onChange={(value) => {
                let newVal = value.map((v) => v.id?.toString?.());
                formik.setFieldValue("parent_education", newVal);
                formik.setFieldValue(
                  "education",
                  optionsData.educationCategory
                    .filter((edu) =>
                      newVal?.includes?.(edu?.parent_id?.toString?.())
                    )
                    .map?.((j) => j.id?.toString?.())
                );
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Education
            </label>
            <Select
              id="education"
              isMulti
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              options={optionsData.educationCategory}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.category_title}
              value={optionsData.educationCategory?.filter((m) =>
                formik.values.education?.includes?.(m.id?.toString?.())
              )}
              onChange={(value) =>
                formik.setFieldValue(
                  "education",
                  value.map((v) => v.id?.toString?.())
                )
              }
            />
          </div>
        </div>

        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Occupation Category
            </label>
            <Select
              id="parent_occupation"
              isMulti
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              options={optionsData.parentJobCategory}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.parent_job_category}
              value={optionsData.parentJobCategory?.filter((m) =>
                formik.values.parent_occupation?.includes?.(m.id?.toString?.())
              )}
              onChange={(value) => {
                let newVal = value.map((v) => v.id?.toString?.());
                formik.setFieldValue("parent_occupation", newVal);
                formik.setFieldValue(
                  "job",
                  optionsData.jobCategory
                    .filter((job) =>
                      newVal?.includes(job?.parent_id?.toString?.())
                    )
                    .map?.((j) => j.id?.toString?.())
                );
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Occupation
            </label>
            <Select
              id="job"
              isMulti
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              options={optionsData.jobCategory}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.subcategory_name}
              value={optionsData.jobCategory?.filter((m) =>
                formik.values.job?.includes?.(m.id?.toString?.())
              )}
              onChange={(value) =>
                formik.setFieldValue(
                  "job",
                  value.map((v) => v.id?.toString?.())
                )
              }
            />
          </div>
        </div>

        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Country
            </label>
            <Select
              id="country"
              isMulti
              isClearable={true}
              placeholder={"Select"}
              options={optionsData.countryList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.country_name}
              value={optionsData.countryList?.filter((m) =>
                formik.values.country?.includes?.(m.id?.toString?.())
              )}
              onChange={(value) =>
                formik.setFieldValue(
                  "country",
                  value.map((v) => v.id?.toString?.())
                )
              }
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              State
            </label>
            <Select
              id="state"
              isMulti
              placeholder={"Select"}
              options={optionsData.stateList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.state_name}
              value={stateValue?.filter?.((c) =>
                formik.values.state?.includes?.(c.id?.toString())
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "state",
                  value.map((a) => a.id?.toString())
                );
                formik.setFieldValue("district", []);
              }}
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              District
            </label>
            <Select
              id="district"
              isMulti
              placeholder={"Select"}
              options={districtValue}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.district_name}
              value={optionsData.districtsList?.filter?.((c) =>
                formik.values.district?.includes?.(c.id?.toString())
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "district",
                  value.map((a) => a.id?.toString())
                );
              }}
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        <div className="col-6">
          <div className={"input-wrap pro-m-2"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Jathakam
            </label>
            <Select
              id="jathakam"
              isMulti
              placeholder={"Select"}
              options={optionsData.jathakamLists}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.jathakam_type}
              value={optionsData.jathakamLists?.filter?.((c) =>
                formik.values.jathakam?.includes?.(c.id?.toString())
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "jathakam",
                  value.map((a) => a.id?.toString())
                );
              }}
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>
        {star && (
          <div className="col-6">
            <div className={"input-wrap pro-m-2"}>
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Matching Stars
              </label>
              <Select
                id="matching_stars"
                isMulti
                placeholder={"Select"}
                options={optionsData?.starsList}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.star_name}
                value={optionsData.starsList?.filter?.((c) =>
                  formik.values.matching_stars?.includes?.(c.id?.toString())
                )}
                onChange={(value) => {
                  formik.setFieldValue(
                    "matching_stars",
                    value?.map((a) => a?.id?.toString())
                  );
                }}
                className={"pro-input "}
                classNamePrefix="pro-input"
              />
            </div>
          </div>
        )}
      </div>

      <div className="col-12 pro-mt-4  pro-d-flex pro-justify-end">
        <Button
          className={`pro-btn pro-btn-primary ${
            state?.partnerMatchSaveStatus === "pending" ? "loading" : ""
          } `}
          onClick={() => handleSave()}
        >
          Save
        </Button>

        <Button
          className={`pro-btn pro-btn-primary pro-ms-3 ${
            state?.partnerMatchStatus === "pending" ? "loading" : ""
          }`}
          onClick={formik.handleSubmit}
        >
          Find Match
        </Button>
      </div>

      {/* {errors && <span className="common-error">{errors}</span>} */}
    </div>
  );
};

export default PartnerForm;
