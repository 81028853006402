export const AdvancedSearchField = {
  photo: {
    label: "Photo",
    hidden: false,
    sortable: true,
    position: 0,
  },
  id_info: {
    label: "ID info",
    hidden: false,
    sortable: true,
    position: 1,
  },
  basic_info: {
    label: "Basic Info",
    hidden: false,
    sortable: false,
    position: 2,
  },
  detailed_info: {
    label: "Detailed Info",
    hidden: false,
    sortable: true,
    position: 3,
  },
  contact_info: {
    label: "Contact info",
    hidden: false,
    sortable: true,
    position: 4,
  },
  other_info: {
    label: "Package info",
    hidden: false,
    sortable: true,
    position: 5,
  }
};
