import { useEffect, useRef, useState } from "react";
import Style from "./CollapsedCard.module.scss"
const CollapsibleCard = ({children,OpeningText,ClosingText,isOpen,setIsOpen}) => {

  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentHeight = contentRef?.current?.children[0]?.scrollHeight;
    if (isOpen) {
      setHeight(contentHeight ? `${contentHeight+10}px` : "1000px");
    } else {
      setHeight(0);
    }
  }, [isOpen, contentRef, children]);

  const toggleCollapse = ({children}) => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={Style.root}>
      <div className="pro-w-100 pro-d-flex pro-justify-end">
      {/* <button onClick={toggleCollapse} className={`collapsible-button pro-btn pro-btn-outline pro-w-auto pro-gap-1 ${isOpen ? "active" : ""}`}>
       {isOpen ? ClosingText : OpeningText}
        <span class={`material-symbols-outlined pro-font-bold x3`}>{isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"} </span>
      </button> */}
      </div>      
      <div style={{height}} ref={contentRef} className={`${Style.content_wrap}`}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleCard;
