import {
  Button,
  HeadingGroup,
  ModalLayout,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import ErrorMessage from "../../Global/ErrorMessage";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import TableShimmer from "../../Global/Shimmers/TableShimmer/TableShimmer";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import AdvancedSearchForm from "./AdvancedSearchForm";
import useAdvancedSearch from "./useAdvancedSearch";
import style from "./advancedSearch.module.scss";
import ViewProfileDetails from "../../Profile/ViewProfileDetails";
import PdfModalForm from "../../Profile/ActionForms/PdfModalForm";
import Select from "react-select";
import ViewTableDetails from "./ViewTableDetails";
import { AdvancedSearchField } from "./static";
import { Table } from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import Assets from "../../../assets/Assets";
import CollapsibleCard from "../../Global/CollapsedCard";
const AdvancedSearch = () => {
  const {
    currentPage,
    formik,
    state,
    showScroll,
    selectedProfiles,
    activeProfile,
    printRef,
    tableRef,
    showPdf,
    skip,
    isFetchLoading,
    paginationOptions,
    advancedSearchResults,
    isFetching,
    isSuccess,
    isError,
    pdfLoading,
    pdf2Loading,
    isChecked,
    exportOption,
    optionsData,
    isSearchFilterSuccess,
    exportLoading,
    hasExportPermission,
    hasDownloadPdfPermission,
    selectedView,
    isOpen,
    setIsOpen,
    getRowCompact,
    getRowList,
    setExportOption,
    handleExportClick,
    handleDownloadTable,
    setIsFetchLoading,
    handleDownloadClick,
    handleSelect,
    handleScrollToTop,
    closePdfModal,
    handleDownloadPdf,
    handleSearch,
    handleReset,
    handlePagination,
    handlePageSize,
    handleSelectAll,
    setSelectedView,
    handleViewChange
  } = useAdvancedSearch();
  return (
    <div className={`pro-mb-4`}>
      {showScroll && (
        <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
          <span className="material-symbols-outlined">expand_less</span>
        </button>
      )}
      <HeadingGroup
        title={
          advancedSearchResults?.data?.data?.length !== undefined
            ? ` ${advancedSearchResults?.data?.total_count} Results`
            : "Advanced Search"
        }
        extraClassName={`${style.fixed_position}`}
      >
        <div className="pro-d-flex pro-items-center">
          <div className="pro-check-box pro-items-center pro-flex-nowrap">
            <input
              type="checkbox"
              id="admin_verified"
              name="admin_verified"
              className="pro-check"
              checked={formik.values.admin_verified}
              onChange={(e) => {
                formik.setFieldValue("admin_verified", e.target.checked);
              }}
            />
            <label
              htmlFor="admin_verified"
              className="pro-check-label single-line"
            >
              Admin Verified
            </label>
          </div>
          <div className="pro-check-box pro-items-center pro-ms-5">
            <input
              type="checkbox"
              id="entire_data"
              name="entire_data"
              className="pro-check"
              checked={formik?.values.entire_data}
              onChange={(e) => {
                formik.setFieldValue("entire_data", e.target.checked);
              }}
            />
            <label htmlFor="entire_data" className="pro-check-label">
              Entire Data
            </label>
          </div>
          <Button
            className={"pro-btn-outline pro-px-5 pro-mx-2"}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            type="submit"
            className={`pro-btn pro-btn-primary ${isFetching ? "loading" : ""}`}
            onClick={handleSearch}
          >
            Search
          </Button>
          <button
            className={
              "collapsible-button pro-btn pro-btn-outline pro-ms-2 pro-gap-1"
            }
            style={{ whiteSpace: "nowrap", height: "32px" }}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen ? "Expand Less" : "Expand More"}
            <span class={`material-symbols-outlined pro-font-bold x1`}>
              {isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}{" "}
            </span>
          </button>
        </div>
      </HeadingGroup>
      <CollapsibleCard
        children={
          <AdvancedSearchForm
            formik={formik}
            optionsData={optionsData}
            isSuccess={isSearchFilterSuccess}
          />
        }
        OpeningText={"Expand More"}
        ClosingText={"Expand Less"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {/* <AdvancedSearchForm
        formik={formik}
        optionsData={optionsData}
        isSuccess={isSearchFilterSuccess}
      /> */}
      {/* {state?.advancedSearchStatus === "pending" ? ( */}
      {isFetching ? (
        selectedView === "grid" ? (
          <div className={`col-12 pro-mt-4`}>
            <div className={`row g-4`}>
              {[...Array(2)].map((_, i) => (
                <div className={`col-6`} key={i}>
                  <UserDetailTableShimmer />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <TableShimmer />
        )
      ) : isSuccess ? (
        <>
          <div className="pro-w-100 pro-d-flex pro-justify-between pro-items-center">
            {selectedView === "grid" && (
              <>
                <div className={`pro-check-box pro-text-nowrap`}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleSelectAll}
                    className="pro-check"
                    id="all"
                  />
                  <label htmlFor="all" className="pro-check-label">
                    Select All
                  </label>
                </div>
              </>
            )}

            <div className="pro-d-flex pro-justify-end pro-w-100">
              <div className="pro-d-flex pro-gap-2 pro-justify-center">
                <button
                   onClick={() => handleViewChange("compact")}
                  disabled={selectedView === "compact"}
                  className="pro-btn pro-btn-outline pro-d-flex pro-gap-1"
                >
                  <img src={Assets.Compactsvg} alt="Compact View" />
                  Compact
                </button>

                <button
                 onClick={() => handleViewChange("list")}
                  disabled={selectedView === "list"}
                  className="pro-btn pro-btn-outline  pro-d-flex pro-gap-1"
                >
                  <img src={Assets.Listsvg} alt="List View" />
                  List
                </button>

                <button
                  onClick={() => handleViewChange("grid")}
                  disabled={selectedView === "grid"}
                  className="pro-btn pro-btn-outline pro-d-flex pro-gap-1"
                >
                  <img src={Assets.Gridsvg} alt="Grid View" />
                  Grid
                </button>
              </div>
              {hasExportPermission && (
                <div className="col-auto pro-ms-3 pro-me-3">
                  <Select
                    id="export_options"
                    isClearable={false}
                    placeholder={"Export Options"}
                    options={optionsData?.data?.export_count}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.value}
                    className={`pro-input lg `}
                    classNamePrefix="pro-input"
                    value={optionsData?.data?.export_count?.filter(
                      (p) =>
                        // registrationReportState?.currentPlatformFilter ===
                        exportOption === p.value
                    )}
                    onChange={(value) => setExportOption(value?.value)}
                  ></Select>
                </div>
              )}
              {hasExportPermission && (
                <button
                  className={`pro-btn pro-btn-primary pro-px-5 lg  ${
                    exportLoading ? "loading" : ""
                  }`}
                  onClick={handleExportClick}
                >
                  Export
                </button>
              )}
              {hasDownloadPdfPermission && (
                <button
                  className={`pro-btn pro-btn-primary pro-px-5 lg pro-ms-3 ${
                    pdf2Loading ? "loading" : ""
                  }`}
                  onClick={handleDownloadTable}
                >
                  NR Download PDF
                </button>
              )}
            </div>
          </div>

          {selectedView === "grid" ? (
            <UserDetailTableListing
              data={advancedSearchResults?.data?.data || []}
              //  lastElement={lastElement}
              search
              handleSelect={(id,checked)=>handleSelect(id,checked,selectedView)}
              checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
              showCheck
              handleDownload={() =>handleDownloadClick(selectedView)}
            />
          ) : selectedView === "compact" ? (
            <Table
              data={advancedSearchResults?.data?.grid_list || []}
              uniqueID={"id"}
              fields={AdvancedSearchField}
              showCheckBox={true}
              handleSelect={(id,checked)=>handleSelect(id,checked,selectedView)}
              checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
              handleEdit={() =>handleDownloadClick(selectedView)}
              editIcon={
                <span className="material-symbols-outlined x3">download</span>
              }
              getRow={getRowCompact}
              loading={isFetching}
              assignable={false}
              editable={true}
              deletable={false}
              editText={"Download"}
              className={"pro-pb-6"}
            />
          ) : (
            <Table
              data={advancedSearchResults?.data?.grid_list || []}
              uniqueID={"id"}
              fields={AdvancedSearchField}
              showCheckBox={true}
              handleSelect={(id,checked)=>handleSelect(id,checked,selectedView)}
              checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
              handleEdit={() =>handleDownloadClick(selectedView)}
              editIcon={
                <span className="material-symbols-outlined x3">download</span>
              }
              getRow={getRowList}
              loading={isFetching}
              assignable={false}
              editable={true}
              deletable={false}
              editText={"Download"}
              // handleEdit={handleTableEditClick}
            />
          )}

          {advancedSearchResults?.data?.data?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === state?.currentPageSize
              )}
              totalPageCount={Math.ceil(
                advancedSearchResults?.data?.total_count /
                  advancedSearchResults?.data?.per_page
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        // state?.advancedSearchStatus === "fulfilled" &&
        isSuccess &&
        advancedSearchResults?.data?.length === 0 && <ErrorMessage />
      )}
      {/* {state?.advancedSearchStatus === "rejected" && <ErrorMessage />} */}
      {isError && <ErrorMessage />}

      <ModalLayout show={showPdf} handleClose={closePdfModal}>
        <PdfModalForm
          handleCancel={closePdfModal}
          handleDownloadPdf={handleDownloadPdf}
          isFetchLoading={isFetchLoading}
          pdfLoading={pdfLoading}
        />
      </ModalLayout>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewProfileDetails
          setIsFetchLoading={setIsFetchLoading}
          ref={printRef}
          userId={activeProfile}
          skip={skip}
        />
      </div>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewTableDetails
          setIsFetchLoading={setIsFetchLoading}
          ref={tableRef}
          userId={activeProfile}
          skip={skip}
        />
      </div>
    </div>
  );
};

export default AdvancedSearch;
