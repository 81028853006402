import React from "react";

const TableShimmer = ({ perpage = 10, feilds = 5 }) => {
  return (
    <table className="pro-table">
      <thead>
        <tr>
          <th width="60">
            <span className="letter shine"></span>
          </th>
          {[...Array(feilds - 1)].map((_, i) => (
            <th width={`${Math.floor(100 / feilds)}%`} key={i}>
              <span className="letter shine"></span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(perpage)].map((_, i) => (
          <tr key={i}>
            <td>
              <span className="letter shine pro-my-2 pro-d-flex"></span>
            </td>
            <td>
              <span className="letter shine pro-my-2 pro-d-flex"></span>
            </td>
            <td>
              <span className="letter shine pro-my-2 pro-d-flex"></span>
            </td>
            <td>
              <span className="letter shine pro-my-2 pro-d-flex"></span>
            </td>
            <td>
              <span className="letter shine pro-my-2 pro-d-flex"></span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableShimmer;
