import { Header, MenuDrawer } from "@wac-ui-dashboard/wac_component_library";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Assets from "../../../assets/Assets";
import ProtectRoute from "../../../utils/components/ProtectRoute";
import HeaderActions from "../HeaderActions";
import Style from "./commonLayout.module.scss";

const CommonLayout = ({ children, drawerMenu }) => {
  return (
    <ProtectRoute>
      <Helmet>
        <title>NEXUS Call Center | Dashboard</title>
      </Helmet>
      <Header
        Link={Link}
        logo={Assets.HeaderLogo}
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div className={`pro-w-100 container-fluid pt-custom`}>
        <div className={`row`}>
          <div
            className={`col-auto wrapper pro-ps-0 ${Style.MenuDrawer_container}`}
          >
            <MenuDrawer
              menus={drawerMenu}
              Link={Link}
              type={`type-2`}
              Nav={Nav}
              icon={
                <span className="material-symbols-outlined ">
                  chevron_right
                </span>
              }
              propStyle={{ type_2: Style.type_2 }}
            />
          </div>
          <div className={`wrapper-table pro-pt-4 pro-pe-5 pro-ps-2 pro-pb-6`}>
            {children}
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default CommonLayout;
